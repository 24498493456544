<template>
  <div>
    <v-card>
      <v-form ref="form" class="multi-col-validation">
        <v-toolbar dense dark color="primary">
          <v-toolbar-title><h4 class="font-weight-light">TRANSFER DATA</h4></v-toolbar-title>
        </v-toolbar>

        <v-layout row wrap class="align-center mx-2 mt-5">
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="date_released"
              dense
              label="Date"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="date"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12>
            <v-select
              outlined
              class="mx-2"
              v-model="reason"
              dense
              label="Reason"
              :items="['CHANGE AMOUNT','TRANSFER','DAMAGE','OTHERS']"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12 v-if="reason==='CHANGE AMOUNT'">
            <v-text-field
              class="mx-2"
              v-model="amount"
              dense
              label="Amount"
              outlined
              :rules="rules.default_max_45_character_and_no_empty_rule"
              type="number"
            ></v-text-field>
          </v-flex>
          <v-flex xs12 md12 v-if="reason==='TRANSFER'">
            <v-select
              outlined
              class="mx-2"
              v-model="category_id"
              dense
              label="Category"
              :items="category_items"
              item-value="id"
              item-text="category"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              v-if="!is_forbidden"
              outlined
              class="mx-2"
              v-model="selection"
              dense
              label="Selection"
              :items="['Branch', 'Regional', 'Territory']"
              :rules="rules.combobox_rule"
              @change="selected_category"
            ></v-select>
            <v-select
              outlined
              class="mx-2"
              v-model="branch_id"
              dense
              label="Branch"
              :items="branch_items"
              item-value="id"
              item-text="branch_code"
              :rules="rules.combobox_rule"
            ></v-select>
          </v-flex>
          <v-flex xs12 md12>
            <v-text-field
              class="mx-2"
              v-model="specify"
              dense
              label="Specify"
              outlined
              :rules="rules.default_max_255_character_and_no_empty_rule"
            ></v-text-field>
          </v-flex>
        </v-layout>

        <v-row>
          <!-- alert -->
          <v-col cols="12" v-show="alert">
            <v-alert color="warning" text class="mb-0">
              <div class="d-flex align-start">
                <v-icon color="warning">
                  {{ icons.mdiAlertOutline }}
                </v-icon>

                <div class="ms-3">
                  <p class="text-base font-weight-medium mb-1">
                    {{ alert_message }}
                  </p>
                </div>
              </div>
            </v-alert>
          </v-col>
          <v-col cols="12" md="12">
            <v-btn class="w-full" color="primary" @click="done_request" v-if="!saving_request">
              Done
            </v-btn>
            <v-progress-circular :size="50" :width="5" color="primary" indeterminate
                                 v-else></v-progress-circular>
          </v-col>
        </v-row>
      </v-form>
    </v-card>
  </div>
</template>
<script>
  import {mdiAlertOutline} from '@mdi/js'
  import {mapActions, mapGetters} from 'vuex'

  const initialState = () => {
    return {
      saving_request: false,
      alert: false,
      alert_message: '',

      date_released: '',
      reason: '',
      selection: '',
      category_id: '',
      branch_id: '',
      amount: '',
      specify: '',
      is_forbidden: false,

      branch_items: [],
      category_items: [],
    }
  }
  export default {
    props: {
      details: Object,
    },
    setup() {
      return {
        icons: {
          mdiAlertOutline,
        },
      }
    },
    data() {
      return initialState()
    },
    mounted() {
      this.initialize_data()
    },
    computed: {
      ...mapGetters('form_rules', ['rules']),
      ...mapGetters('authentication', ['employee_id']),
    },
    methods: {
      ...mapActions('inventory_history', ['register_inventory_history']),
      ...mapActions('branch_information', ['branch_data']),
      ...mapActions('category', ['list_of_all_categories']),
      initialize_data() {
        this.list_of_all_categories()
          .then(response => {
            this.category_items = response.data
          })
          .catch(error => {
            console.log(error)
          })
      },
      async selected_category() {
        this.is_forbidden = true
        if (this.category_id === 4 || this.category_id === 3) {
          this.is_forbidden = false
        } else {
          this.selection = ''
        }
        this.branch_id = ''
        await this.branch_data({
          category_id: this.category_id,
          is_region: this.selection === 'Regional' ? 1 : 0,
          is_territory: this.selection === 'Territory' ? 1 : 0,
        })
          .then(response => {
            this.branch_items = response.data[0].active
          })
          .catch(error => {
            console.log(error)
          })
      },
      done_request() {
        this.saving_request = true
        this.alert = false
        if (this.$refs.form.validate()) {
          const data = new FormData()
          data.append('inventory_id', this.details.id)
          data.append('date', this.date_released)
          data.append('reason', this.reason)
          data.append('specify', this.specify.toUpperCase())
          data.append('category_id', this.reason === 'TRANSFER' ? this.category_id : '')
          data.append('to_branch_id', this.reason === 'TRANSFER' ? this.branch_id : '')
          data.append('to_amount', this.reason === 'CHANGE AMOUNT' ? this.amount : 0)
          this.register_inventory_history(data)
            .then(response => {
              this.$emit('response', response)
              this.saving_data = false
            })
            .catch(error => {
              console.log(error)
            })
        } else {
          this.alert = true
          this.alert_message = 'Please Fill up the field/s'
          this.saving_request = false
        }
      },
    },
  }
</script>
